<template>
  <div class="data-table" ref="dataTable">
    <a-table
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
      :scroll="scroll"
      bordered
    >
      <div slot="operate" slot-scope="text, record">
        <a @click="handleOperate('unbinding', record)">解绑</a>
      </div>
    </a-table>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin'

const columns = [
  {
    title: '用户微信external_user_id',
    dataIndex: 'externalUserId',
    width: 150,
    align: 'center'
  },
  {
    title: '用户微信昵称',
    dataIndex: 'externalUserName',
    width: 120,
    align: 'center'
  },
  {
    title: '用户微信类型',
    key: 'externalWxType',
    width: 120,
    align: 'center',
    customRender: (text, record, index) => {
      return record.externalWxType === 1 ? '个人微信' : '企业微信'
    }
  },
  {
    title: '客服企微id',
    dataIndex: 'customerServiceWxId',
    width: 120,
    align: 'center'
  },
  {
    title: '客服企微姓名',
    dataIndex: 'customerServiceWxName',
    width: 120,
    align: 'center'
  },
  {
    title: '好友状态',
    key: 'status',
    width: 90,
    align: 'center',
    customRender: (text, record, index) => {
      return record.status === 1 ? '已加' : '已删'
    }
  },
  {
    title: '加好友时间',
    dataIndex: 'addTime',
    width: 118,
    align: 'center'
  },
  {
    title: '绑定时间',
    dataIndex: 'bindTime',
    width: 118,
    align: 'center'
  },
  {
    title: '操作',
    key: 'operate',
    width: 62,
    align: 'center',
    fixed: 'right',
    scopedSlots: { customRender: 'operate' }
  }
]

export default {
  name: 'WechatTable',
  mixins: [resizeMixin],
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      columns,
      scroll: {}
    }
  },
  mounted() {
    this.setTableScroll()
    this.$_resizeHandler = () => {
      this.setTableScroll()
    }
  },
  methods: {
    handleOperate(type, record) {
      this.$emit('operate', type, record)
    },
    setTableScroll() {
      const width = this.$refs.dataTable.offsetWidth
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0)
      const scroll = {}

      if (width < columnsWidth) {
        scroll.x = columnsWidth
      }

      if (this.data.length > 5) {
        scroll.y = 500
      }

      this.scroll = scroll
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
