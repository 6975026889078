<template>
  <a-card class="page-container">
    <a-row :gutter="60">
      <a-col :span="16">
        <!-- 用户信息 -->
        <div class="user-info">
          <div class="user-info__hd">
            <a-avatar :size="64" icon="user" />
          </div>
          <div class="user-info__bd">
            <div class="user-name">
              <span>{{ userDetail.name || '--' }}</span>
              <span>{{ userDetail.principal || '--' }}</span>
              <span>{{ userDetail.dealerName || '--' }}</span>
            </div>
            <div class="act-name">
              {{ userDetail.username || '--' }}
            </div>
          </div>
        </div>

        <!-- 店端情况记录 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>店端情况记录：</span>
            <a-icon class="icon-form" type="form" @click="openModal(1)" />
          </div>
          <div class="info-block__bd">
            <a-row>
              <a-col :span="6">
                <span>短视频人设：{{ userDetail.awemeType | awemeTypeFilter }}</span>
              </a-col>
              <a-col :span="6">
                <span>直播人设：{{ userDetail.liveType | liveTypeFilter }}</span>  
              </a-col>
              <a-col :span="6">
                <span>店端状态：{{ userDetail.storeStatus | storeStatusFilter }}</span>
              </a-col>
              <a-col :span="6">
                <span>主播状态：{{ userDetail.authorStatus | authorStatusFilter }}</span>
              </a-col>
            </a-row>
          </div>
        </div>

        <!-- 帐号信息 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>帐号信息：</span>
            <a-icon class="icon-form" type="form" @click="openModal(2)" />
          </div>
          <div class="info-block__bd">
            <div class="account-item" v-for="(item, index) in defaultAuthorList" :key="index">
              <i class="icon icon-douyin" v-if="item.platform === '抖音'"></i>
              <i class="icon icon-kuaishou" v-if="item.platform === '快手'"></i>
              <span>{{ item.nickname }}</span>
              <span>【默认】</span>
            </div>
          </div>
        </div>

        <!-- 企微关系 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>企微关系：</span>
            <a-icon class="icon-form" type="form" @click="openModal(2)" />
          </div>
          <div class="info-block__bd">
            <WechatTable
              :data="userDetail.wxCustomerServiceList"
              @operate="handleOperate"
            />
          </div>
        </div>

        <!-- 店端配置 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>店端配置：</span>
            <a-icon class="icon-form" type="form" @click="openModal(3)" />
          </div>
          <div class="info-block__bd">
            <div v-if="userDetail.storeConfig">
              {{ userDetail.storeConfig }}
            </div>
            <div v-else>暂无店端配置</div>
          </div>
        </div>

        <!-- 奖励政策 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>奖励政策：</span>
            <a-icon class="icon-form" type="form" @click="openModal(4)" />
          </div>
          <div class="info-block__bd">
            <div v-if="userDetail.incentivePolicy">
              {{ userDetail.incentivePolicy }}
            </div>
            <div v-else>暂无奖励政策</div>
          </div>
        </div>

        <!-- 潜力判定记录 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>潜力判定记录：</span>
          </div>
          <div class="info-block__bd">
            <template v-if="userDetail.potentialities && userDetail.potentialities.length > 0">
              <div class="capacity-card" v-for="(item, index) in userDetail.potentialities" :key="index">
                <div class="capacity-item">
                  <div class="capacity-item__hd">
                    {{ item.activityName || '--' }}
                  </div>
                  <div class="capacity-item__bd">
                    <div>{{ item.potentiality | potentialityFilter }}</div>
                    <div>{{ item.month }}</div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="capacity-card">
                <div class="capacity-tips">
                  暂无潜力判定记录
                </div>
              </div>
            </template>
          </div>
        </div>

        <!-- 异常/其他记录 -->
        <div class="info-block">
          <div class="info-block__hd">
            <span>异常/其他记录：</span>
          </div>
          <div class="info-block__bd">
            <template v-if="userDetail.remarks && userDetail.remarks.length > 0">
              <div class="ex-item" v-for="(item, index) in userDetail.remarks" :key="index">
                <div class="ex-item__time">
                  【{{ item.type }}】{{ item.ctime }}
                </div>
                <div class="ex-item__text">
                  {{ item.action }}
                </div>
              </div>
            </template>
            <template v-else>
              <div>暂无异常/其他记录</div>
            </template>
          </div>
        </div>
      </a-col>

      <a-col :span="8">
        <!-- 分析总结 -->
        <div class="summary">
          <div class="summary__hd">
            分析总结：  
          </div>
          <div class="summary__bd">
            <div class="summary-card" v-if="userDetail.analysisMonth">
              <div class="summary-card__hd">
                <div class="name">
                  {{ userDetail.analysisMonth.activityName || '--' }}
                </div>
                <div>
                  <a-tag color="#108ee9">月分析</a-tag>
                  <span class="time">
                    {{ userDetail.analysisMonth.month || '--' }}
                  </span>
                  <span class="capacity">
                    {{ userDetail.analysisMonth.potentiality | potentialityFilter }}
                  </span>
                </div>
              </div>
              <div class="summary-card__bd">
                <div class="summary-content">
                  <div class="summary-content__title">问题总结：</div>
                  <div class="summary-content__text">
                    {{ userDetail.analysisMonth.summary || '--' }}
                  </div>
                </div>

                <div class="summary-content">
                  <div class="summary-content__title">改善措施：</div>
                  <div class="summary-content__text">
                    {{ userDetail.analysisMonth.measure || '--' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-card" v-else>
              <div class="summary-card__bd">
                <div class="summary-tips">暂无月分析总结</div>
              </div>  
            </div>
            
            <div class="summary-card" v-if="userDetail.analysisWeek">
              <div class="summary-card__hd">
                <div class="name">
                  {{ userDetail.analysisWeek.activityName || '--' }}
                </div>
                <div>
                  <a-tag color="#108ee9">周分析</a-tag>
                  <span class="time">
                    {{ userDetail.analysisWeek.week }}
                  </span>
                </div>
              </div>
              <div class="summary-card__bd">
                <div class="summary-content">
                  <div class="summary-content__title">问题总结：</div>
                  <div class="summary-content__text">
                    {{ userDetail.analysisWeek.summary || '--' }}
                  </div>
                </div>

                <div class="summary-content">
                  <div class="summary-content__title">改善措施：</div>
                  <div class="summary-content__text">
                    {{ userDetail.analysisWeek.measure || '--' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-card" v-else>
              <div class="summary-card__bd">
                <div class="summary-tips">暂无周分析总结</div>
              </div>  
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <!-- 编辑店端情况记录弹框 -->
    <store-modal
      :visible="storeModalVisible"
      :form="storeModalForm"
      :aweme-type-list="awemeTypeList"
      :live-type-list="liveTypeList"
      :store-status-list="storeStatusList"
      :author-status-list="authorStatusList"
      :confirm-loading="confirmLoading"
      @confirm="handleStoreModalConfirm"
      @cancel="handleStoreModalCancel"
    />

    <!-- 编辑帐号信息弹框 -->
    <author-modal
      :visible="authorModalVisible"
      :form="authorModalForm"
      :douyin-account-list="douyinAccountList"
      :kuaishou-account-list="kuaishouAccountList"
      :account-log-list="accountLogList"
      :confirm-loading="confirmLoading"
      @confirm="handleAuthorModalConfirm"
      @cancel="handleAuthorModalCancel"
    />

    <!-- 编辑店端配置弹框 -->
    <store-config-modal
      :visible="storeConfigModalVisible"
      :form="storeConfigModalForm"
      :confirm-loading="confirmLoading"
      @confirm="handleStoreConfigModalConfirm"
      @cancel="handleStoreConfigModalCancel"
    />

    <!-- 编辑奖励政策弹框 -->
    <reward-modal
      :visible="rewardModalVisible"
      :form="rewardModalForm"
      :confirm-loading="confirmLoading"
      @confirm="handleRewardModalConfirm"
      @cancel="handleRewardModalCancel"
    />
  </a-card>
</template>

<script>
import WechatTable from './components/WechatTable'
import StoreModal from './components/StoreModal'
import AuthorModal from './components/AuthorModal'
import StoreConfigModal from './components/StoreConfigModal'
import RewardModal from './components/RewardModal'
import {
  storeStatusList,
  storeStatusFilter,
  authorStatusList, 
  authorStatusFilter,
  awemeTypeList,
  awemeTypeFilter,
  liveTypeList,
  liveTypeFilter,
  potentialityFilter
} from '@/utils/fhlUserConst'
import fhlUserApi from '@/api/fhlUser'

export default {
  name: 'userDetail',
  components: {
    WechatTable,
    StoreModal,
    AuthorModal,
    StoreConfigModal,
    RewardModal
  },
  data () {
    return {
      userDetail: {},
      authorList: [],
      storeModalVisible: false,
      storeModalForm: {
        awemeType: '',
        liveType: '',
        storeStatus: '',
        authorStatus: '',
        remarkType: '',
        remark: ''
      },
      awemeTypeList,
      liveTypeList,
      storeStatusList,
      authorStatusList,
      confirmLoading: false,
      authorModalVisible: false,
      authorModalForm: {
        username: '',
        dealerName: '',
        dealerId: '',
        douyinMediaAccount: '',
        kuaishouMediaAccount: ''
      },
      accountLogList: [],
      storeConfigModalVisible: false,
      storeConfigModalForm: {
        storeConfig: ''
      },
      rewardModalVisible: false,
      rewardModalForm: {
        incentivePolicy: ''
      }
    }
  },
  created () {
    this.fetchFhlUserDetail()
    this.fetchFhlUserAuthorList()
  },
  computed: {
    defaultAuthorList () {
      return this.authorList.filter(item => item.isBusiness === 'true')
    },
    douyinAccountList () {
      return this.authorList.filter(item => item.platform === '抖音')
    },
    kuaishouAccountList () {
      return this.authorList.filter(item => item.platform === '快手')
    }
  },
  methods: {
    // 获取风火轮用户详情
    async fetchFhlUserDetail () {
      try {
        const params = {
          userId: this.$route.query.userId
        }
        const { code, data, message } = await fhlUserApi.fetchFhlUserDetail(params)

        if (code === 200) {
          this.userDetail = data
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 获取媒体帐号列表
    async fetchFhlUserAuthorList () {
      try {
        const params = {
          userId: this.$route.query.userId
        }
        const { code, data, message } = await fhlUserApi.fetchFhlUserAuthorList(params)

        if (code === 200) {
          this.authorList = data || []
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 打开弹框
    openModal (type) {
      if (type === 1) { // 编辑店端情况记录
        const {
          awemeType,
          liveType,
          storeStatus,
          authorStatus,
          remarkType,
          remark
        } = this.userDetail

        this.storeModalForm = {
          awemeType: awemeType !== null ? awemeType : '',
          liveType: liveType !== null ? liveType : '',
          storeStatus: storeStatus !== null ? storeStatus : '',
          authorStatus: authorStatus !== null ? authorStatus : '',
          remarkType: remarkType !== null ? remarkType : '',
          remark: remark !== null ? remark : ''  
        }
        this.storeModalVisible = true  
      }

      if (type === 2) { // 编辑帐号信息
        const { username, dealerName, dealerId } = this.userDetail
        let douyinMediaAccount = ''
        let kuaishouMediaAccount = ''

        this.defaultAuthorList.forEach(item => {
          if (item.platform === '抖音') {
            douyinMediaAccount = item.authorId
          }

          if (item.platform === '快手') {
            kuaishouMediaAccount = item.authorId
          }
        })

        this.authorModalForm = {
          username,
          dealerName,
          dealerId,
          douyinMediaAccount,
          kuaishouMediaAccount
        }

        this.fetchAccountLog()
        this.authorModalVisible = true
      }

      if (type === 3) { // 编辑店端配置
        const { storeConfig } = this.userDetail

        this.storeConfigModalForm = {
          storeConfig: storeConfig !== null ? storeConfig : ''
        }
        this.storeConfigModalVisible = true  
      }
      
      if (type === 4) { // 奖励政策
        const { incentivePolicy } = this.userDetail
  
        this.rewardModalForm = {
          incentivePolicy: incentivePolicy !== null ? incentivePolicy : ''
        }
        this.rewardModalVisible = true
      }
    },
    //
    async fetchAccountLog () {
      try {
        const params = {
          userId: this.$route.query.userId
        }
        const { code, data, message } = await fhlUserApi.fetchAccountLog(params)

        if (code === 200) {
          this.accountLogList = data.map(item => item)
          console.log(this.accountLogList)
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    //
    handleStoreModalConfirm (form) {
      this.updateFhlUserBaseInfo(form)
    },
    // 更新风火轮用户基本信息
    async updateFhlUserBaseInfo (form) {
      this.confirmLoading = true
      try {
        const { remarkType, remark, ...restForm } = form
        let params = {
          userId: this.$route.query.userId,
          ...restForm,
          remarkType,
          remark
        }
        const { code, message } = await fhlUserApi.updateFhlUserBaseInfo(params)

        if (code === 200) {
          this.$message.success('编辑成功')
          this.confirmLoading = false
          this.storeModalVisible = false
          this.fetchFhlUserDetail()
        } else {
          this.$message.error(message)
          this.confirmLoading = false
        }
      } catch (e) {
        console.log(e)
        this.confirmLoading = false
      }
    },
    //
    handleStoreModalCancel () {
      this.storeModalVisible = false
    },
    //
    handleAuthorModalConfirm (form) {
      this.updateFhlUserDefaultAuthor(form)
    },
    // 更新风火轮用户默认媒体帐号
    async updateFhlUserDefaultAuthor (form) {
      this.confirmLoading = true
      try {
        const { douyinMediaAccount, kuaishouMediaAccount } = form
        const douyinArr = this.douyinAccountList.filter(item => item.authorId === douyinMediaAccount)
        const kuaishouArr = this.kuaishouAccountList.filter(item => item.authorId === kuaishouMediaAccount)
        let params = {
          userId: this.$route.query.userId,
          douyinMediaAccount,
          douyinMediaAccountName: douyinMediaAccount ? douyinArr[0].nickname : '',
          kuaishouMediaAccount,
          kuaishouMediaAccountName: kuaishouMediaAccount ? kuaishouArr[0].nickname : ''
        }

        const { code, message } = await fhlUserApi.updateFhlUserDefaultAuthor(params)

        if (code === 200) {
          this.$message.success('编辑成功')
          this.confirmLoading = false
          this.authorModalVisible = false
          this.fetchFhlUserAuthorList()
        } else {
          this.$message.error(message)
          this.confirmLoading = false
        }
      } catch (e) {
        console.log(e)
        this.confirmLoading = false
      }
    },
    //
    handleAuthorModalCancel () {
      this.authorModalVisible = false
    },
    //
    handleStoreConfigModalConfirm (form) {
      this.updateFhlUserStoreConfig(form)
    },
    // 更新风火轮用户店端配置
    async updateFhlUserStoreConfig (form) {
      this.confirmLoading = true
      try {
        const params = {
          userId: this.$route.query.userId,
          ...form
        }
        const { code, message } = await fhlUserApi.updateFhlUserStoreConfig(params)

        if (code === 200) {
          this.$message.success('编辑成功')
          this.confirmLoading = false
          this.storeConfigModalVisible = false
          this.fetchFhlUserDetail()
        } else {
          this.$message.error(message)
          this.confirmLoading = false
        }
      } catch (e) {
        console.log(e)
        this.confirmLoading = false
      }
    },
    //
    handleStoreConfigModalCancel () {
      this.storeConfigModalVisible = false
    },
    //
    handleRewardModalConfirm (form) {
      this.updateFhlUserIncentivePolicy(form)
    },
    // 更新风火轮用户奖励政策
    async updateFhlUserIncentivePolicy (form) {
      this.confirmLoading = true
      try {
        const params = {
          userId: this.$route.query.userId,
          ...form
        }
        const { code, message } = await fhlUserApi.updateFhlUserIncentivePolicy(params)

        if (code === 200) {
          this.$message.success('编辑成功')
          this.confirmLoading = false
          this.rewardModalVisible = false
          this.fetchFhlUserDetail()
        } else {
          this.$message.error(message)
          this.confirmLoading = false
        }
      } catch (e) {
        console.log(e)
        this.confirmLoading = false
      }
    },
    //
    handleRewardModalCancel () {
      this.rewardModalVisible = false
    },
    handleOperate(type, record) {
      type === 'unbinding' && this.unbinding(record)
    },
    async unbinding(record) {
      try {
        const params = {
          fhlUserId: this.$route.query.userId,
          externalUserId: record.externalUserId
        }
        const { code, message } = await fhlUserApi.unbinding(params)

        if (code === 200) {
          this.$message.success('解绑成功')
          this.fetchFhlUserDetail()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  filters: {
    awemeTypeFilter,
    liveTypeFilter,
    storeStatusFilter,
    authorStatusFilter,
    potentialityFilter
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.user-info {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgba(#000, .65);
  
  &__hd {
    padding: 10px 10px 20px;
  }

  &__bd {
    flex: 1;
    padding: 10px 10px 20px;
  }
}

.user-name {
  font-size: 18px;
  font-weight: bold;

  span {
    margin: 0 20px 0 0;
  }
}

.act-name {
  margin: 10px 0 0;
}

.info-block {
  margin: 20px 0 0;

  &__hd {
    display: flex;
    align-items: center;
    padding: 10px;
    font-weight: bold;

    & .icon-form {
      margin: 0 0 0 10px;
      color: #1890ff;
      font-size: 18px;
      cursor: pointer;
    }
  }

  &__bd {
    padding: 10px 10px 10px 30px;
  }
}

.account-item {
  display: flex;
  align-items: center;
  margin: 0 0 10px;

  & .icon {
    display: inline-block;
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &-douyin {
      background-image: url("../../../../assets/icon/icon_douyin.png");
    }

    &-kuaishou {
      background-image: url("../../../../assets/icon/icon_kuaishou.png");
    }

    &-dongchedi {
      background-image: url("../../../../assets/icon/icon_dongchedi.png");
    }
  }
}

.capacity-card {
  padding: 10px 20px;
  background: #ccc;
  border-radius: 10px;
  overflow: hidden; 
  
  & div {
    padding: 5px 0;
    text-align: center;
  }

  & + .capacity-card {
    margin: 16px 0 0;
  }
}

.capacity-item {
  display: flex;
  align-items: center;

  &__hd {
    width: 300px;
  }

  &__bd {
    flex: 1;
    
    & > div {
      text-align: left;
    }
  }
}

.ex-item {
  margin: 0 0 10px;
}

.summary {
  &__hd {
    padding: 40px 0 0;
    font-size: 18px;
    font-weight: bold;
  }
}

.summary-card {
  margin: 20px 0 0;
  background: #ccc;
  border-radius: 10px;
  overflow: hidden;

  &__hd {
    position: relative;
    padding: 20px 20px 10px;

    & .name {
      margin: 0 0 16px;
    }

    & .ant-tag {
      font-size: 12px;
      font-weight: bold;
    }

    & .time {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
    }

    & .capacity {
      position: absolute;
      right: 20px;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  &__bd {
    padding: 10px 20px 20px;
  }
}

.summary-content {
  margin: 0 0 20px;

  &__text {
    margin: 10px 0 0;
    padding: 0 0 0 10px;
  }
}

.summary-tips {
  padding: 10px 0 0;
  text-align: center;
}
</style>
