<template>
  <a-modal
    title="编辑奖励政策"
    :visible="visible"
    :width="820"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{span: 3}"
      :wrapper-col="{span: 21}"
    >
      <a-form-model-item label="奖励政策" prop="incentivePolicy">
        <a-textarea
          v-model="form.incentivePolicy"
          :rows="4"
          placeholder="请按需选填，200字以内。"
        />
         <p class="tips">此信息提交后，会更新到【主播档案】作为历史跟踪信息</p>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'RewardModal',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        incentivePolicy: [
          { max: 200, message: '最多可输入200字', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    handleCancel () {
      this.$refs.form.resetFields()
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.tips {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
}
</style>