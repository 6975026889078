<template>
   <a-modal
    title="编辑帐号信息"
    :visible="visible"
    :width="820"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{span: 3}"
      :wrapper-col="{span: 21}"
    >
      <a-form-model-item class="mb-8" label="帐号" prop="username">
        {{ form.username || '--' }}  
      </a-form-model-item>
      <a-form-model-item class="mb-8" label="经销商名称" prop="dealerName">
        {{ form.dealerName || '--' }}
      </a-form-model-item>
      <a-form-model-item class="mb-8" label="经销商ID" prop="dealerId">
        {{ form.dealerId || '--' }}
      </a-form-model-item>

      <a-tabs v-model="activeTab">
        <a-tab-pane tab="媒体帐号" :key="1">
          <div>更换默认媒体帐号</div>
          <a-form-model-item prop="douyinMediaAccount">
            <div>抖音</div>
            <a-radio-group v-model="form.douyinMediaAccount">
              <a-radio v-for="item in douyinAccountList" :key="item.authorId" :value="item.authorId">
                <span>{{ item.nickname }}</span>
                <span v-if="item.isBusiness === 'true'">【默认】</span>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item prop="kuaishouMediaAccount">
            <div>快手</div>
            <a-radio-group v-model="form.kuaishouMediaAccount">
              <a-radio v-for="item in kuaishouAccountList" :key="item.authorId" :value="item.authorId">
                <span>{{ item.nickname }}</span>
                <span v-if="item.isBusiness === 'true'">【默认】</span>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-tab-pane>

        <a-tab-pane tab="变更记录" :key="2">
          <div v-for="(item, index) in accountLogList" :key="index">
            <div class="record-item" v-if="index < accountLogList.length - 1">
              <div class="record-item__time">
                {{ item.ctime }}
              </div>
              <div class="record-item__content">
                <p><span>{{ item.operator }}</span>修改了默认媒体号信息。</p>
                <p><span>{{ accountLogList[index + 1].action || '--' }}</span>修改为<span>{{ item.action }}</span>。</p>
              </div>
            </div>
            <div class="record-item" v-else>
              <div class="record-item__time">
                {{ item.ctime }}
              </div>
              <div class="record-item__content">
                <p><span>{{ item.operator }}</span>修改了默认媒体号信息。</p>
                <p><span>--</span>修改为<span>{{ item.action }}</span>。</p>
              </div>
            </div>
          </div>  
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </a-modal>  
</template>

<script>
export default {
  name: 'AuthorModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    douyinAccountList: {
      type: Array,
      default () { return [] }
    },
    kuaishouAccountList: {
      type: Array,
      default () { return [] }  
    },
    accountLogList: {
      type: Array,
      default () { return [] }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
   data () {
    return {
      rules: {},
      activeTab: 1
    }
  },
  methods: {
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    handleCancel () {
      this.$refs.form.resetFields()
      this.activeTab = 1
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-8 {
  margin-bottom: 8px;
}

.ant-radio-wrapper {
  display: block;

  & + .ant-radio-wrapper {
    margin: 10px 0 0;
  }
}

.record-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 16px;

  &__time {
    line-height: 1.5;
  }

  &__content {
    flex: 1;
    margin: 0 0 0 16px;

    p {
      margin: 0;
      line-height: 1.5;

      span {
        color: #1890ff;
      }
    }
  }
}
</style>
