<template>
  <a-modal
    title="编辑店端情况记录"
    :visible="visible"
    :width="820"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{span: 6}"
      :wrapper-col="{span: 18}"
    >
      <a-row :gutter="60">
        <a-col :span="12">
          <a-form-model-item  label="短视频人设" prop="awemeType">
            <a-select v-model="form.awemeType" style="width: 100%;">
              <a-select-option
                v-for="item in awemeTypeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="直播人设" prop="liveType">
            <a-select v-model="form.liveType" style="width: 100%;">
              <a-select-option
                v-for="item in liveTypeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="店端状态" prop="storeStatus">
            <a-select v-model="form.storeStatus" style="width: 100%;">
              <a-select-option
                v-for="item in storeStatusList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="主播状态" prop="authorStatus">
            <a-select v-model="form.authorStatus" style="width: 100%;">
              <a-select-option
                v-for="item in authorStatusList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item prop="remarkType">
            <a-radio-group v-model="form.remarkType">
              <a-radio :value="2">
                异常
              </a-radio>
              <a-radio :value="1">
                其他
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item
            label="备注"
            prop="remark"
            :label-col="{span: 2}"
            :wrapper-col="{span: 22}"
          >
            <a-textarea
              v-model="form.remark"
              :rows="4"
              placeholder="请按需选填，100字以内。"
            />
            <p class="tips">此信息提交后，会更新到【主播档案】作为历史跟踪信息</p>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: '',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    awemeTypeList: {
      type: Array,
      default () { return [] }
    },
    liveTypeList: {
      type: Array,
      default () { return [] }
    },
    storeStatusList: {
      type: Array,
      default () { return [] }
    },
    authorStatusList: {
      type: Array,
      default () { return [] }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        remark: [
          { max: 100, message: '最多可输入100字', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      })
    },
    handleCancel () {
      this.$refs.form.resetFields()
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.tips {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
}
</style>
